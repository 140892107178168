import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
// import { useTheme } from ''
import Image from 'next/image'
// import { useHasMounted } from '../hooks/useHasMounted'
import { styled } from 'ui'
import { useTheme } from '@styles/theme'
import { useHasMounted } from '@hooks/useHasMounted'

const SunMoonWrapper = ({ children }: { children: React.ReactNode }) => {
	return (
		<motion.div
			initial={{ opacity: 1, x: 50, y: 100, rotate: 0, scale: 0.33 }}
			animate={{ opacity: 1, x: 0, y: 0, rotate: -5, scale: 1.1 }}
			exit={{ opacity: 1, x: 50, y: 100, rotate: -25, scale: 0.33 }}
			transition={{ duration: 0.66, ease: 'easeInOut' }}
			whileHover={{ scale: 1.2 }}>
			{children}
		</motion.div>
	)
}

const Wrap = styled('div', {
	position: 'relative',
	transform: 'scale(.5)',
	'@bp640': {
		transform: 'scale(.55)',
	},
})

export const Sunrise = () => {
	const { currentTheme } = useTheme()
	const hasMounted = useHasMounted()

	if (!hasMounted) return null

	return (
		<Wrap>
			<AnimatePresence mode='wait'>
				{currentTheme === 'dark' && (
					<SunMoonWrapper key='moon'>
						<Image
							src='/img/moon.png'
							width={90}
							height={80}
							key='moon-image'
							alt='moon'
							quality={100}
							priority
						/>
					</SunMoonWrapper>
				)}
				{currentTheme === 'light' && (
					<SunMoonWrapper key='sun'>
						<Image
							src='/img/sun.png'
							width={90}
							height={80}
							key='sun-image'
							alt='sun'
							quality={100}
							priority
						/>
					</SunMoonWrapper>
				)}
			</AnimatePresence>
			<motion.div style={{ zIndex: 10, translateZ: 2 }} key='cloud'>
				<Image
					src='/img/cloud2.png'
					width={450}
					height={320}
					alt='cloud floating in the background'
				/>
			</motion.div>
		</Wrap>
	)
}
